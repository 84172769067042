body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:where(.css-dev-only-do-not-override-1okl62o).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
  border: 1px solid rgba(0, 0, 0, 0.50) !important;
}
.sign {
  align-items: center;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-section {
  display: flex;
  height: 100%;
}

.sign-text {
  width: 69%;
}

.sign-text h2 {
  margin-bottom: -16px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sign-text p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.email-area {
  width: 72%;
  margin-top: 11px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  background-color: #00B3EB !important;
}
.css-1aquho2-MuiTabs-indicator{
  background-color: #ffffff !important;
  border-bottom: none !important;
}

.email-area p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.email-area input {
  border-radius: 13px;
  border: 1px solid rgba(0, 0, 0, 0.50);
  width: 92%;
  padding: 14px;
}

.select-module {
  border-radius: 9px !important;
  border: 1px solid rgba(0, 0, 0, 0.50) !important;
  width: 90% !important;
}

.forgot-p {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sign-button {
  border-radius: 20px;
  background: #00C495;
  border: transparent;
  width: 100%;
  margin: 0 auto;
  height: 50px;
  display: block;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #FFF;
  cursor: pointer;
}

.img-google {
  width: 100%;
  cursor: pointer;
}

.img-logo {
  width: 201px;
  height: 251px;
  margin: 0 auto;
  display: block;
}

.img-sign {
  width: 50%;
  margin: 0 auto;
  display: block;
}

.sign-right {
  width: 50%;
  height: 100%;
  background-color: rgb(0, 179, 235);
  padding-bottom: 15px;
}

.sign-left {
  width: 50%;
}
.hover-button:hover {
  background: #00B3EB !important;
  color: #000 !important;
}

@media only screen and (max-width: 768px) {
  .sign-right {
    display: none;
  }

  .sign-left {
    width: 100%;
  }

  .forgot-p {
    font-size: 11PX;
  }

  .sign-button {
    border-radius: 14px;
    background: #00C495;
    border: transparent;
    width: 100%;
    margin: 0 auto;
    height: 38px;
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #FFF;
    cursor: pointer;
  }

  .email-area input {
    padding: 14px;
  }

  .sign-text h2 {
    font-size: 29px;
    text-align: center;
  }
}

/* ===== create account */

.First-Name label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.First-Name input {
  width: 82%;
  padding: 14px;
}

.First-Name {
  width: 50%;
}

.First-Name p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.date-section {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 67%;
  justify-content: space-between;
  margin: 19px
}

.create-inputs {
  display: flex;
}

@media only screen and (max-width: 1094px) {
  .create-inputs {
    flex-wrap: wrap;
  }

  .First-Name {
    width: 95%;
  }

  .First-Name input {
    width: 100%;
  }

  .password-btn {
    border-radius: 20px;
    background: #00C495;
    width: 173px;
    height: 44px;
    border: transparent;
    color: #FDF4F4;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }

  .password-btn {
    border: transparent;
    color: #FDF4F4;
    font-size: 14px !important;
  }

  .Respond-btn {
    width: 156px;
    height: 40px;
    border: transparent;
    color: #FDF4F4;
    font-size: 14px;
  }

  .close-btn {
    width: 130px !important;
    height: 51px !important;
    font-size: 14px !important;
  }
}

.password-btn {
  border-radius: 20px;
  background: #00C495;
  width: 196px;
  height: 55px;
  border: transparent;
  color: #FDF4F4;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.Approve-btn {
  border-radius: 20px;
  background: #00C495;
  width: 112px;
  height: 34px;
  font-size: 10px;
  border: transparent;
  color: #FDF4F4;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.close-btn {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.50);
  width: 139px;
  height: 55px;
  font-size: 16px;
  background-color: #FFF;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

/* ======blue Goat dashboard */

.sidebar {
  width: 20%;
  position: relative;
}

.password-btn {
  border-radius: 20px;
  background: #00C495;
  width: 196px;
  height: 55px;
  border: transparent;
  color: #FDF4F4;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.close-btn {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.50);
  width: 139px;
  height: 55px;
  font-size: 16px;
  background-color: #FFF;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}


.bg {
  width: 20%;
  background-color: #00B3EB;
  height: 100%;
  position: fixed;
  overflow: scroll;
  z-index: 999;
}

.profile-photo img {
  width: 70px;
  height: 70px;
  margin-top: 30px;
}

.profile-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

}

.name {
  color: #ffff;
}

.name h6 {
  font-size: 24px;
}

.name p {
  margin-top: -53px;
}

.side-menu ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  /* margin-left: 8px; */
  cursor: pointer;
  color: #fff;


}

.side-menu ul p {
  width: 100%;
}

.side-menu ul li img {
  width: 16px;
}

.side-logo {
  display: flex;
  justify-content: center;
}

.side-logo img {
  width: 200px;
}

.side-menu a {
  color: #fff;
  text-decoration: none;
  display: block;

  &:hover {
    background-color: #fff;
    color: #0041C4;
  }

  &:hover ul li {
    background-color: #fff;
    color: #0041C4;
  }
}

.right-side {
  width: 80%;
}

.nav {

  background: #FFF;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.10);
  height: 81px;
  width: 100%;
}

.nav-area {
  display: flex;
  justify-content: space-between;
  padding: 25px 28px;
}

.nav h4 {
  margin: 0;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.goat-boxes {
  display: flex;
  gap: 15px;
  margin: 44px auto;
  justify-content: center;
  flex-wrap: wrap;
}

.goat-box {
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  width: 25%;
  display: flex;
  padding: 10px 26px;
  gap: 20px;
  cursor: pointer;
  min-width: 182px;
}

.icon-div {
  border-radius: 11px;
  background: #3498DB;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  width: 89px;
  height: 86px;
}

.icon-div img {
  width: 50px;
  height: 50px;
  margin: auto;
  display: block;
  padding-top: 13px;
}

.box-text p {
  font-size: 12px;
  font-style: normal;
  font-weight: 00;
  line-height: normal;
  margin-bottom: 0;
}

.box-text h4 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.dot-message {
  display: flex;
  align-items: center;
  gap: 7px;
  padding-left: 56px;
}

.dot-message p {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.messages-area {
  width: 83%;
  display: flex;
  height: 45px;
  border-radius: 20px;
  align-items: center;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  gap: 11px;
  padding: 9px;
  justify-content: center;
  margin: 19px auto;
}

.messages-area img {
  width: 45px;
  height: 45px;
}

.messages-text h4 {
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.messages-text p {
  margin: 0;
  color: #858585;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appointment {
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  width: 89%;
  height: 308px;
  margin-top: 15px;
}

.appointment img {
  width: 100%;
  height: 111px;
}

.appointment-area {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.appointment h4 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 40px;
}

.appointment-area span {
  color: #FF2D2D;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appointment-area p {
  color: #0041C4;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.other-people {
  width: 75%;
  padding: 20px 9px 14px;
  margin: 24px auto 0px;
  border-radius: 20px;
  background: #00C495;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.people {
  display: flex;
  align-items: center;
}

.people p {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.people img {

  width: 39px;
  height: 39px;
}

.search-icon {
  cursor: pointer;
  margin-left: 5px;
}

.Add-MHC {
  width: 172px;
  height: 34px;
  border-radius: 20px;
  background: #00B3EB;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: transparent;
  cursor: pointer;
}

.admin-heading {
  display: flex;
  /* justify-content: space-around; */
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 auto;
  width: 95%;
}

.admin-text h4 {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.admin-text {
  display: flex;
  gap: 6px;
  cursor: pointer;
}

.admin-text p {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.admin-area p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.admin-area {
  width: 96%;
  display: flex;
  height: 45px;
  border-radius: 20px;
  align-items: center;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  padding: 9px 5px 9px 9px;
  /* justify-content: space-between; */
  margin: 19px auto;
}

.admin-area img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.edit-btn {
  width: 90px;
  height: 36px;
  border-radius: 20px;
  border: 1px solid #000;
  cursor: pointer;
  background: #fff;
}

.view-btn {
  width: 90px;
  height: 36px;
  ;
  border-radius: 20px;
  background: #00C495;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  border: transparent;
  line-height: normal;
  cursor: pointer;
}

.survey-btn {
  width: 117px;
  height: 34px;
  border-radius: 20px;
  background: #8E44AD;
  color: #FFF;
  border: transparent;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.client-area {
  display: flex;
  width: 74%;
  gap: 20px;
  margin: 55px auto;
  align-items: center;
}

.client-area img {
  width: 58px;
}

.client-area h4 {
  color: #16558F;
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.client-area p {
  color: #858585;
  margin: 0;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;

}

.Edit-Survey {
  width: 149px;
  height: 34px;
  border-radius: 20px;
  background: #00B3EB;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: transparent;
  cursor: pointer;
}

.Submit-Survery {
  color: #FFF;
  width: 149px;
  height: 34px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 20px;
  background: #00C495;
  border: transparent;
  cursor: pointer;
}

.employe-inputs {
  width: 82%;
  margin: 0 auto;
}
.employe-inputsmhc {
  width: 100%;
  margin-left: 60px;
}

.ContactInformation {
  margin: 32px auto;
}

button.btn.btn-primary {
  border-radius: 22px;
  height: 2px;
  border: solid #05CC55;
  background-color: #05CC55;
  color: #fff;
  position: relative;
  right: 34px;
  width: 33px;
  top: 141px;
  cursor: pointer;
  padding: 14px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.attendance-area h4 {

  margin-left: 58px;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.attendance-heading {
  width: 90%;
  height: 45px;
  border-radius: 20px;
  align-items: center;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  padding: 9px 9px 9px 9px;
  justify-content: space-between;
  margin: 19px auto;
  display: flex;

}

.attendance-p {
  width: 25%;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  line-height: normal;
}

.admin-p {
  width: 25%;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  line-height: normal;
}

.admin-role {
  width: 25%;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  line-height: normal;
}

.zoom-btn {
  width: 77%;
  height: 76px;
  border-radius: 20px;
  margin: 20px auto;
  border: 1px solid #000;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.zoom-btn img {
  width: 35px;
  height: auto;
}

.zoom-btn h4 {
  margin: 0;
}

.role-p {
  width: 25%;
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: normal;
}

.role-p {
  width: 25%;
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin: 0;
  line-height: normal;
}

.role-area {
  width: 94%;
  display: flex;
  height: 45px;
  border-radius: 20px;
  align-items: center;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  padding: 9px 6px 9px 6px;
  justify-content: space-between;
  margin: 19px auto;
}

.message-area {
  width: 88%;
  height: 46px;
  margin: 18px auto;
  padding: 11px;
  border-radius: 20px;
  background: #F3F3F3;
  display: flex;
  justify-content: space-between;
}

.message-area img {
  width: 45px;
  height: 47px;
}

.message-area h4 {
  margin: 0;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.message-area p {
  margin: 0;
  color: #858585;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.overview-boxes {
  width: 22%;
  min-width: 198px;
  height: 196px;
  padding: 15px 5px;
  border-radius: 42px;
  background: #00B3EB;
}

.overview-area {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.overview-text {
  display: flex;
}

.overview-text h4 {
  color: #FFF;
  font-size: 49px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin: 0;
  margin-left: 16px;
}

.overview-text h6 {
  margin: 0;
  margin-left: 23px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.overview-text p {
  color: #FFF;
  margin-left: 23px;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.circle {
  border: 2px solid #14FF00;
  border-radius: 31px;
  width: 55px;
  height: 55px;
  margin: 0 auto;

}

.circle span {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  margin: 18px;
  justify-content: center;
}

.report-area input {
  width: 144px;
  min-width: 139px;
  height: 35px;
  border-radius: 5px;
  background: #E5E5E5;
  border: transparent;
}

.report-area {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

.report-area select {
  width: 150px;
  min-width: 139px;
  height: 37px;
  border-radius: 5px;
  background: #E5E5E5;
  border: transparent;
}

.filter-btn {
  width: 150px;
  min-width: 80px;
  height: 37px;
  border-radius: 5px;
  background: #00B3EB;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: transparent;
  cursor: pointer;
}

.download-report {
  width: 150px;
  height: 142px;
  background-color: #00B3EB;
  border-radius: 74px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 34px;
  bottom: 18px;
  cursor: pointer;
}

.download-report img {
  width: 52px;
}

.download-report p {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.module-text {
  margin-left: 27px;
}

.module-text h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.module-text p {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.progress-btn {
  width: 40%;
  height: 55px;
  border-radius: 11px;
  background: #3498DB;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: transparent;
  cursor: pointer;
}

.module-buttons {
  display: flex;
  width: 65%;
  gap: 13px;
  float: right;
  margin-right: 40px;
}

.module-box {
  width: 90%;
  height: auto;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  margin: auto;
  margin-top: 69px;
  padding-left: 30px;
  margin-bottom: 24px;
  overflow: scroll;
}

.module-box h4 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.module-box p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.library-sreach h1 {
  color: #000;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.library-diagnostic h1 {
  color: #000;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.library-diagnostic h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sreach-library {
  width: 78%;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #000;
  padding: 5px;
  padding-left: 40px;
  font-size: 18px;
}

.library-list {
  width: 85%;
  margin: 0 auto;
  padding: 0 16px;
  overflow: scroll;
}

.library-list h4 {
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.library-list h6 {
  margin: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.library-list p {
  margin: 0;
  color: rgba(0, 0, 0, 0.60);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.library-box {
  gap: 2px;
  display: flex;
  width: 95%;
  height: 43px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.10);
  margin-top: 25px;
  padding: 20px;
  align-items: center;
}

.addtraining-list {
  width: 80%;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup-uploadfile {
  width: 63%;
  height: auto;
  background: #FFF;
  border: 2px solid #00B3EB;
  margin: 30px auto;
}

.popup-header {
  height: 57px;
  display: flex;
  background: #00B3EB;
  justify-content: space-between;
  padding: 0px 17px;
  color: #FFF;
  align-items: center;
}

.popup-header p {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.popup-header .X {
  cursor: pointer;
}

.popup-inputs {
  width: 80%;
  margin: 20px auto;
}

.popup-inputs p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.popup-inputs input {
  width: 100%;
  height: 30px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.50);
  padding: 11px;
  font-size: 18px;
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 999;

}
/* ===== Scroll Bar Css */

::-webkit-scrollbar {
  width: 8px;
  height: 100%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.clients-boxes {
  width: 90%;
  height: auto;
  padding: 11px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  margin-bottom: 20px;
}

.clients-area {
  display: flex;
  height: 45px;
  border-radius: 20px;
  align-items: center;
  gap: 11px;
  padding: 4px;
  justify-content: center;
  margin: auto;
}

.clients-area img {
  width: 45px;
  height: 45px;
}

.clients-text {
  width: 35%;
}

.clients-text h4 {
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.clients-text p {
  margin: 0;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Decline-btn {
  width: 112px;
  height: 34px;
  border-radius: 20px;
  border: 1px solid #000;
  background-color: #fff;
  color: #292929;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}




:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}



.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 29px;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.msg:last-of-type {
  margin: 0;
}

.msger {
  width: 80%;
}

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.msg-bubble {
  width: 35%;
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}

.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}

.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #fff;
  border-bottom-right-radius: 0;
}

.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05)
}

.msger-inputarea * {
  padding: 13px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
  width: 16%;
  border-radius: 16px;
}

.msger-input {
  flex: 1;
  background: #ddd;
}

.msger-send-btn {
  margin-left: 10px;
  background: #00C495;
  border-radius: 20px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  /* transition: background 0.23s; */
}

.search input {
  width: 80%;
  margin: 18px auto;
  display: block;
  border: 1px;
  padding: 8px;
  border-radius: 7px;
}

.clearfix {
  display: flex;
  gap: 13px;
  background: #fff;
  width: 86%;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
}

.clearfix img {
  width: 50px;
}

ul.list {
  padding-left: 12px;
}

.nav-mobile {
  display: none;
}

.nav-mobile img {
  width: 165px;
  margin: -25px auto;
  display: block;
}

@media screen and (max-width: 768px) {
  .nav-mobile {
    display: block;
    width: 100%;
    height: 150px;
    background-color: rgb(0, 179, 235);
  }
}

.status {
  font-size: 14px;
}

.upload-img {
  width: 161px;
  height: 161px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 23px;
}

.role-popup {
  width: 60%;
  padding-bottom: 18px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  margin: 40px auto;
  z-index: 9999;
}

.role-selectbtn {
  display: flex;
  gap: 11px;

}

.role-selectbtn label {
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.role-selectbtn select {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.50);
  height: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 7px;
}

.role-list h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.role-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}


.role-list p {
  font-size: 16px;
  font-style: normal;
  margin: 0;
  font-weight: 500;
  line-height: 1.5;
}

.apply-btn {
  width: 20%;
  height: 52px;
  border-radius: 11px;
  background: #27AE60;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: transparent;
  cursor: pointer;
}

.cancel-btn {
  width: 20%;
  height: 52px;
  border-radius: 11px;
  background: #FF3E3E;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: transparent;
  cursor: pointer;
}

.ellipsis-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.sidemenu-img {
  width: 30px;
  position: absolute;
  z-index: 999;
  top: 28px;
  left: 20px;
}

@media screen and (max-width:990px) {
  .mob-resp {
    display: none;
  }

  .right-side {
    width: 100%;
  }

  .nav h4 {
    margin-left: 25px;
  }

  .bg {
    width: 252px;
  }

  .notification {
    width: 40% !important;
  }
}

@media screen and (min-width:990px) {
  .sidemenu-img-lft {
    display: none;
  }

  .sidemenu-img {
    display: none;
  }
}

@media screen and (max-width:900px) {
  .role-mainpopup {
    gap: 16px !important;
  }

  .role-selectbtn {
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .role-selectbtn select {
    width: 60%;
  }

  .role-selectbtn label {
    font-size: 16px;
  }
}

.role-mainpopup {
  display: flex;
  justify-content: space-between;
  padding: 40px 60px;
  align-items: center;
  flex-wrap: wrap;
}

.sidemenu-img-lft {
  width: 30px;
  position: absolute;
  z-index: 999999999999999999;
  top: 28px;
  left: 249px;
}

@media screen and (max-width:500px) {
  .overview-boxes {
    width: 100% !important;
  }

  .report-area select {
    width: 184px;
  }

  .report-area input {
    width: 184px;
  }

  .report-area {
    justify-content: center;
  }

  .notification {
    width: 50% !important;
  }

  .management-input button {
    width: 114px !important;
    height: 38px !important;
    color: #fff !important;
    font-size: 10px;
  }
}

@media screen and (max-width:460px) {
  .goat-box {
    width: 60%;
  }

  .notification {
    width: 80% !important;
  }

  .messages-text p {
    width: 163px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .clients-text {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Decline-btn {
    width: 91px;
  }

  .Approve-btn {
    width: 97px;
  }

  .report-area select {
    width: 235px
  }

  .report-area input {
    width: 235px
  }

  .filter-btn {
    width: 235px
  }
}

@media screen and (max-width:700px) {
  .role-popup {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border-radius: 0;
  }
}

@media screen and (max-width:824px) {
  .admin-responsive{
    width: 100%;
    overflow: scroll;
  }
  .message-bluegoat {
    width: 100% !important;
  }

  .dot-message {
    padding-left: 56px !important;
  }

  .appointment {
    margin: 0 auto;
  }

  .view-btn {
    margin-right: 0px !important;
  }

  .client-area {
    width: 90%;
    gap: 12px !important;
    flex-wrap: wrap;
  }

  .client-text {
    width: 78%;
  }

  .Edit-Survey {
    width: 117px;
    margin-left: 66px;
  }

  .Submit-Survery {
    width: 117px;
  }

  .Submitted-mobile {
    margin-left: 66px;
  }

  .module-buttons {
    width: 97%;
    gap: 4px;
    float: right;
    margin-right: 4px;
    margin-top: 12px
  }

  .progress-btn {
    width: 75% !important;
    height: 40px;
    font-size: 11px;
  }

  .library-heading {
    justify-content: center !important;
  }

  .form-mobile {
    width: 100% !important;
    min-width: 330px;
  }

  .sreach-library {
    width: 75%;
    height: 40px;
  }

  .sreach-btns {
    width: 65% !important;
  }

  .library-sreach h1 {
    font-size: 42px;
  }

  .library-diagnostic h1 {
    font-size: 35px;
  }

  .library-management h1 {
    font-size: 30px !important;
  }

  .overview-boxes {
    width: 46%;
  }

  .management-input input {
    width: 80% !important;
  }

  .download-report {
    width: 81px;
    height: 81px;

  }

  .download-report img {
    width: 30px;
  }

  .download-report p {
    display: none;
  }

  .profile-btn {
    width: 40% !important;
  }

  .mobile-view {
    display: none;
  }

  .msger {
    width: 100%;
  }

  .msg-bubble {
    width: 50%;
  }

  .vedios-training {
    justify-content: center;
  }

  .vedios-training video {
    width: 100%;
  }

  .new-user {
    width: 80% !important;
  }

  .select-library {
    width: 80% !important;
  }
}

.library-heading {
  display: flex;
  gap: 25px;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 95%;
}

.message-bluegoat {
  width: 60%;
}

.Submitted-mobile {
  display: flex;
}

.form-mobile {
  width: 54%;

}

.sreach-btns {
  display: flex;
  gap: 11px;
  width: 34%;
}

.profile-btn {
  width: 20%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0px auto 25px;
}

.vedios-training {
  display: flex;
  gap: 20px;
  margin: 44px 24px 0px;
  text-align: center;
  flex-wrap: wrap;
}

.notification {
  position: absolute;
  right: 34px;
  top: 66px;
  width: 23%;
  height: 455px;
  background-color: #fff;
  border: 1px solid #767676;
  border-radius: 22px;
  z-index: 999;
}

.notification header {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.Avatar-area {
  display: flex;
  align-items: center;
  padding: 11px;
  gap: 11px;
}

.Avatar-area img {
  width: 50px;
  height: 50px;
}

.Avatar-text h3 {
  margin: 2px 0;
  font-size: 14px;
}

.Avatar-text p {
  margin: 0;
  font-size: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 13px;
}

.new-user {
  width: 35%;
  background-color: #fff;
  border: 1px solid #767676;
  border-radius: 22px;
  margin: 120px auto;
}

.new-user h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  margin: 8px;
}

.user-inputs p {
  margin: 18px 0 7px 0;
}

.library-diagnostic {
  margin: 0 48px;
}

.library-diagnostic p {
  color: rgba(0, 0, 0, 0.60);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;
}

.textarea-library {
  width: 95%;
  height: 80px;
  border-radius: 20px;
  border: 1px solid #000;
  padding: 11px;
}

.new-library h4 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 8px auto;
}

.new-library input {
  width: 85%;
  padding: 11px;
  height: 24px;
  border-radius: 15px;
  border: 1px solid #000;
}

.select-library {
  width: 35%;
  padding: 7px;
  height: 50px;
  border-radius: 13px;
  border: 1px solid #000;
}

.library-management {
  margin: 0 48px;
}

.library-management h1 {
  color: #000;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.library-management h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 8px auto;
}

.library-management p {
  color: #7D7D7D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 8px 0 17px 0;
}

.management-input input {
  width: 40%;
  padding: 5px 8px;
  height: 40px;
  border-radius: 15px;
  font-size: 14px;
  border: 1px solid #000;
}

.management-input {
  margin-top: 15px;
}

.management-input button {
  width: 145px;
  height: 38px;
  color: #fff;
  border: transparent;
  border-radius: 11px;
  background: #00B3EB;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  cursor: pointer;
}

.library-management-Popup {
  position: absolute;
  width: 55%;
  border-radius: 57px;
  height: 610px;
  background: #D9D9D9;
  margin: 12px auto;
}

.library-management-Popup h2 {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 26px 0;
  text-align: center;
}

.management-list {
  background-color: #fff;
  padding: 34px;
  border-radius: 11px;

}

.management-list p {
  color: #7D7D7D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 8px;
}

.management-list-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.management-list-area p {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.management-list-area button {
  border-radius: 11px;
  background: #00B3EB;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  border: transparent;
  color: #fff;
  width: 24%;
  height: 36px;
  cursor: pointer;
}

@media screen and (max-width:1205px) {
  .library-management-Popup h2 {
    font-size: 23px;
  }

  .management-list p {
    font-size: 14px;
  }

  .management-list-area p {
    font-size: 16px;
  }
}

@media screen and (max-width:1024px) {
  .library-management-Popup h2 {
    font-size: 20px;
  }

  .management-list p {
    font-size: 12px;
  }

  .management-list-area p {
    font-size: 11px;
  }

  .management-list-area button {
    width: 20%;
    height: 34px;
  }
}

@media screen and (max-width:900px) {
  .library-management-Popup h2 {
    font-size: 20px;
  }

  .management-list p {
    font-size: 12px;
  }

  .management-list-area p {
    font-size: 11px;
  }

  .management-list-area button {
    width: 20%;
    height: 34px;
  }

  .library-management-Popup {
    position: absolute;
    width: 100%;
    border-radius: 0;
    height: 100%;
    background: #D9D9D9;
    margin: auto;
  }

  .library-management-Popup h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 26px 0;
    text-align: center;
  }

  .management-list p {
    color: #7D7D7D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 8px;
  }

  .management-list-area p {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media screen and (max-width:600px) {
  .library-management-Popup h2 {
    font-size: 20px;
  }

  .management-list p {
    font-size: 12px;
  }

  .management-list-area p {
    font-size: 11px;
  }

  .management-list-area button {
    width: 20%;
    height: 34px;
  }

  .library-management-Popup-body {
    height: auto !important;
  }

  .library-diagnostic p {
    width: 100%;
  }
}

.library-management-Popup-body {
  display: flex;
  gap: 18px;
  flex-direction: column;
  overflow: scroll;
  height: 475px;
  width: 85%;
  margin: 0px auto;
}

.survey-heading h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.survey-heading {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 50px auto;
  align-items: center;
  flex-wrap: wrap;
}

.untitled-form {
  width: 85%;
  height: auto;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  align-items: center;
  padding: 22px;
  margin: 44px auto;
}


.untitled-form h4 {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.untitled-form h5 {
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.untitled-form p {
  color: #7A7A7A;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 12px auto;
}

.custom-dropdown {
  position: relative;
}
:where(.css-dev-only-do-not-override-1qfezbu).ant-select-single .ant-select-selector{
  border-radius: 13px !important;
}
.custom-dropdown select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px 30px 5px 10px;
  /* Adjust as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  width: 200px;
  /* Adjust as needed */
  height: 42px;
}

.custom-dropdown select:focus {
  border-color: #007bff;
}

.custom-dropdown select option {
  padding: 5px;
}

.custom-dropdown select option:first-child {
  color: #888;
}

.untitled-question {
  width: 85%;
  height: 180px;
  border-radius: 20px;
  background: #FFF;
  margin: 0 auto;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
  padding: 25px;

}

.untitled-input {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 11px;
  align-items: center;
}

.untitled-input input {
  border-radius: 7px;
  border: 1px solid #000;
  width: 40%;
  height: 29px;
  padding: 5px;
}

.Summary-detail {
  margin: 0 auto;
  width: 95%;
}

.Summary-detail h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  text-decoration: underline;
  margin-left: 24px;
}

.Summary-detail ul li {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Summary-detail ul li span {
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}

.Summary-profile {
  display: flex;
  width: 90%;
  margin: 55px auto;
}

.Summary-profile img {
  width: 100%;
}

.Summary-profile div {
  width: 300px;
  height: auto;
}

@media screen and (max-width:877px) {
  .Summary-profile {
    flex-wrap: wrap;
  }

  .Summary-profile div {
    width: 69%;
    margin: 0 auto;
  }

  .Summary-profile img {
    width: 100%;
    height: 344px;
  }

  .custom-dropdown select {
    width: 100%;
  }

  .main-patient {
    flex-wrap: wrap;
  }

  .Patient-detail {
    width: 100% !important;
  }
}

.Patient-detail {
  width: 75%;
  padding: 20px;
}

.Patient-detail h5 {
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.Patient-information {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #343434;
  background: #FFF;
  width: 95%;
  height: auto;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
}

.Patient-inputs {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
  gap: 24px;
}

.Patient-inputs p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.Patient-inputs input {
  width: 60%;
  height: 30px;
  border: 1px solid #000;
}

.Patient-inputs textarea {
  width: 60%;
  height: 70px;
  border: 1px solid #000;
}

.main-patient {
  display: flex;
}

@media screen and (max-width:660px) {
  .untitled-question {
    height: 231px;
  }

  .Patient-popup {
    width: 80% !important;
  }

  .untitled-btns button {
    width: 50% !important;
  }

  .untitled-input input {
    width: 67%;
  }
}

.Patient-popup {
  margin: 150px auto;
  width: 40%;
  padding: 20px;
  height: 140px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
}

.Patient-popup div {
  display: flex;
  gap: 20px;
}

.Patient-popup input {
  width: 56%;
  height: 35px;
  border: 1px solid;
}

.Patient-popup button {
  border-radius: 20px;
  background: #00B3EB;
  width: 93px;
  height: 31px;
  color: #fff;
  border: transparent;
  float: right;
  cursor: pointer;
}

.untitled-btns button {
  width: 18%;
  height: 42px;
  border-radius: 14px;
  background: #00B3EB;
  color: #fff;
  border: transparent;
  cursor: pointer;
}

.custom-dropdown select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px 0px 5px 27px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  width: 100px;
  height: 32px;
  cursor: pointer;
}

.custom-dropdown {
  position: relative;
  cursor: pointer;
  width: 132px;
}

.untitled-btns {
  display: flex;
  gap: 11px;
  justify-content: flex-end;
  padding: 20px 53px;
}

.css-2m9kme-MuiFormControl-root {
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  margin: 8px;
  width: 197px !important;
}

.date-table {
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-list {
  height: 100px;
  width: 12%;
  border: 1px solid;
}

.date-list spam {
  position: relative;
  top: 6px;
  left: 6px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header-datetime {
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-header {
  width: 12%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 25px;
}

.datetime-button {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  padding: 20px;
}

.datetime-button h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.date-list:hover {
  background-color: #cdcdcd42;
  cursor: pointer;
}

.date-list:focus {
  background-color: #cdcdcd42;
  cursor: pointer;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  animation-duration: 10ms;
  padding: 6.5px 14px !important;
}

.css-4jnixx-MuiStack-root>.MuiTextField-root {
  width: 93%;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 9px !important;
  border: 1px solid rgba(0, 0, 0, 0.50);
  height: 42px;
}

.employe-inputs h5 {
  font-size: 25px;
}

.rola-img-area {
  display: flex;
  gap: 6px;
  padding: 5px;
  width: 25%;
  align-items: center;
}

.rola-img-area img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.payment-option {}

.payment-option h4 {
  font-size: 25px;
}

.payment-box {
  border: 1px solid gray;
  height: auto;
  border-radius: 13px;
  padding: 11px;
}


.payment-heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.payment-heading h4 {
  font-size: 20px;
  font-weight: 600;
  color: #394ff3;
}


/* ======new */
.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
}

.col-25 {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
}

.col-50 {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
}

.col-75 {
  -ms-flex: 75%; /* IE10 */
  flex: 75%;
}

.col-25,
.col-50,
.col-75 {
  padding: 0 18px;
}

.container {
  background-color: #f2f2f2;
  padding: 5px 20px 15px 20px;
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.row input[type=text] {
  width: 95%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 11px;
}
.row input[type=text]:focus {
  border-color: #00B3EB !important;
}
.row label {
  margin-bottom: 10px;
  display: block;
}


.icon-container img{
  width: 22%;
  cursor: pointer;
}

.btn {
  background-color: #00B3EB;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: none;
  width: 100%;
  border-radius: 14px;
  cursor: pointer;
  font-size: 17px;
}

.btn:hover {
  background-color: #00B3EB;
}

.row a {
  color: #fff;
  text-decoration: none;
}

.row hr {
  border: 1px solid lightgrey;
}

.row span.price {
  float: right;
  color: #fff;
}
@media (max-width: 800px) {
  .row {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .col-25 {
    margin-bottom: 20px;
  }
}
.w-13{
  width: 13%;
}
.w-14{
  width: 14%;
}
.w-15{
  width: 15%;
}
.w-16{
  width: 16%;
}
.w-17{
  width: 17%;
}
.font-13{
  font-size: 13px;
}